import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Page } from '../components/page'
import { CallToAction } from '../components/call-to-action'
import { Teaser } from '../components/teaser'
import { useTranslation } from 'react-i18next'
import { PersonDescription } from '../components/persondescription'
import { Seo } from '../components/seo'
import { Content } from '../components/content'

const AboutPage = ({ data }: PageProps<Queries.AboutPageQuery>) => {
    const { t }: { t: any } = useTranslation()
    return (
        <Page pageName={'ABOUT'}>
            <Seo title={t('TEASER.ABOUT.HEADER')} />
            <Teaser
                header={t('TEASER.ABOUT.HEADER')}
                text={t('TEASER.ABOUT.TEXT')}
                image={
                    '/images/teasers/edge2edge-media-x21KgBfOd_4-unsplash.jpg'
                }
            />
            <Content>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 'calc(var(--padding) * 2)',
                        alignItems: 'center',
                    }}
                >
                    {data.site?.siteMetadata?.partners
                        ?.map((partner: any) => ({
                            ...partner,
                            key: partner.key.replace('.', '_'),
                        }))
                        .map(({ key, name }: any, i: number) => (
                            <>
                                <PersonDescription
                                    key={key}
                                    id={key}
                                    name={name.first}
                                    description={t(
                                        `PAGES.ABOUT.PERSONDESCRIPTIONS.${key}.DESCRIPTION`
                                    )}
                                    cite={t(
                                        `PAGES.ABOUT.PERSONDESCRIPTIONS.${key}.CITE`
                                    )}
                                    reversed={i % 2 !== 0}
                                />
                            </>
                        ))}
                </div>
            </Content>
            <CallToAction />
        </Page>
    )
}

export default AboutPage

export const query = graphql`
    query AboutPage($language: String!) {
        site {
            siteMetadata {
                contact {
                    email
                }
                partners {
                    key
                    name {
                        first
                        last
                    }
                }
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
