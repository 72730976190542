import * as React from 'react'
import classNames from 'classnames'
import * as personDescriptionStyles from './persondescription.module.css'

export interface PersonDescriptionProps {
    id: string
    name: string
    description: string
    cta?: {to: string, text: string}
    cite: string
    reversed?: boolean
}

export const PersonDescription: React.FC<PersonDescriptionProps> = ({
    id,
    name,
    description,
    cta,
    cite,
    reversed,
}) => {
    return (
        <div
            className={classNames(personDescriptionStyles.container, {
                [personDescriptionStyles.reversed]: reversed,
            })}
        >
            <h2>{name}</h2>
            <img src={`/images/team/${id}.jpg`}/>
            <div className={personDescriptionStyles.text}>
                <h2>{name}</h2>
                <p>{description}</p>
                <p>
                    <b>{cite}</b>
                </p>
            </div>
        </div>
    )
}
